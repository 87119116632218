

















import { Vue, Component } from "vue-property-decorator";
import Plans from '@/store/plans';

@Component
export default class extends Vue {
  headers = [
    { text: "Name", value: "name" },
    { text: "Type", value: "type" },
    { text: "Fee", value: "fee" },
    { text: "Active", value: "active" }
  ];

  mounted() {
    Plans.fetchAll();
  }

  get loading() {
    return Plans.loading;
  }

  get error() {
    return Plans.error;
  }

  get plans() {
    return Plans.plans;
  }
}
