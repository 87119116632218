



















































































import { Vue, Component } from "vue-property-decorator";
import { omit } from 'ramda';
import Plans from '@/store/plans';
import App from '@/store/app';

@Component
export default class extends Vue {
  required = (v: any) => !!v || v === false || "This field is required";
  minZero = (v: number) => v >= 0 || "Enter value higher or equal 0." 
  isEdit = false;
  loading = false;
  loadingSave = false;
  loadingRemove = false;
  error = '';
  errorSave = '';
  statuses = [
    { text: "Active", value: true },
    { text: "Disabled", value: false }
  ];
  types = [
    { text: "One-time", value: 'one-time' },
    { text: "Recurring", value: 'recurring' }
  ];
  saved = false;

  model = {
    name: '',
    desc: '',
    type: 'one-time',
    fees: {
      monthly: 0,
      deposit: 0,
      dailyLower: 0,
      minCharge: 0,
    },
    maxContainers: 0,
    maxAccessories: 0,
  }

  get id() {
    return this.$route.params.id;
  }

  mounted() {
    if (this.id) {
      this.refresh();
    }
  }

  async refresh() {
    this.loading = true;
    try {
      const plan = await Plans.fetchOne({ slug: this.id });
      this.model = omit(['_id', 'slug', '__v', 'createdAt', 'updatedAt'], plan) as any;
      this.isEdit = true;
    } catch (err) {
      this.error = err.message;
    }
    this.loading = false;
  }

  async saveEdit() {
    await Plans.update({ slug: this.id, data: this.model as any });
    App.showSnackbar({ message: 'Plan updated' });
  }

  async create() {
    const created = await Plans.create(this.model as any);
    App.showSnackbar({ message: 'Plan created' });
    this.$router.push(`/plans/${created.slug}`);
    this.isEdit = true;
  }

  async save() {
    this.saved = false;

    if (!(this.$refs as any).form.validate()) {
      this.errorSave = 'Please correct highlighted errors';
      return;
    }
    
    this.loadingSave = true;
    this.errorSave = '';
    try {
      if (this.isEdit) {
        await this.saveEdit();
      } else {
        await this.create();
      }
      this.loadingSave = false;
      this.saved = true;
    } catch (err) {
      this.errorSave = err.message;
    }
    this.loadingSave = false;
  }

  async remove() {
    this.loadingRemove = true;
    try {
      await Plans.remove({ slug: this.id });
      this.$router.push('/plans');
    } catch (err) {
      window.alert(err.message);
    }
    this.loadingRemove = false;
  }
}
