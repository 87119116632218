import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './store';

export interface Plan {
  slug: string;
  name: string;
  desc?: string;
  active: boolean;
  type: 'one-time' | 'recurring';
  fees?: {
    monthly?: number;
    deposit?: number;
    lowerDaily?: number;
    minCharge?: number;
  };
  maxContainers: number;
  maxAccessories: number;
}

export type CreatePlanPayload = {
  name: string;
  desc?: string;
  active?: boolean;
  type?: 'one-time' | 'recurring';
  fees?: any;
  maxContainers?: number;
  maxAccessories?: number;
}

export type UpdatePlanPayload = Partial <CreatePlanPayload>;

@Module({ namespaced: true, name: 'plans', dynamic: true, store, preserveState: true })
class Plans extends VuexModule {
  plans: Plan[] = [];
  error = '';
  loading = false;

  @Mutation
  setPlans(payload: Plan[]) {
    this.plans = payload;
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.plans = [];
    this.error = '';
    this.loading = false;
  }

  @Action
  async fetchAll() {
    this.setLoading(true);
    this.setError('');
    try {
      const response = await Vue.$axios.get('/plans');
      this.setPlans(response.data);
    } catch (err) {
      this.setError(err.message || "Cannot fetch plans");
    }
    this.setLoading(false);
  }

  @Action({ rawError: true })
  async create(data: CreatePlanPayload) {
    return Vue.$axios.post('/plans', data).then((res: any) => res.data);
  }

  @Action({ rawError: true })
  async fetchOne(data: { slug: string }) {
    return Vue.$axios.get(`/plans/${data.slug}`).then((res: any) => res.data);
  }

  @Action({ rawError: true })
  async update(data: { slug: string, data: UpdatePlanPayload }) {
    return Vue.$axios.patch(`/plans/${data.slug}`, data.data).then((res: any) => res.data);
  }

  @Action({ rawError: true })
  async remove(data: { slug: string }) {
    return Vue.$axios.delete(`/plans/${data.slug}`).then((res: any) => res.data);
  }

  @Action
  async resetStateAction() {
    this.resetState();
  }
}

export default getModule(Plans);
